import React from "react";
import download from "downloadjs";
import { Canvas } from "./Canvas";
import { AddressInput } from "./AddressInput";

export const MapDisplay = () => {
const [value, setValue] = React.useState({address: ""})

  return (
    <div className="flex flex-col items-center">
      <AddressInput value={value} setValue={setValue} />
      <Canvas latLng={value?.latLng} />
    </div>
  );
};

const downloadMapImage = () => {
  download(
    "https://maps.googleapis.com/maps/api/staticmap?center=2128+Halleck+Ave+SW,Seattle,WA&zoom=18&size=1280x1280&scale=2&maptype=hybrid&key=AIzaSyB1WHQPb434r37qVAQYoC53f5_cyaxMWmg"
  );
};
