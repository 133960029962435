import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric";
import React, { useRef, useState } from "react";
import download from "downloadjs";
import { useScreenshot } from "use-screenshot-hook";

const url =
  "https://maps.googleapis.com/maps/api/staticmap?center=2128+Halleck+Ave+SW,Seattle,WA&zoom=18&size=1280x1280&scale=2&maptype=hybrid&key=AIzaSyB1WHQPb434r37qVAQYoC53f5_cyaxMWmg";

function getMapURL(latLng = {}) {
  if (!latLng?.lat || !latLng?.lng) return url;
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latLng.lat},${latLng.lng}&zoom=18&size=1280x1280&scale=2&maptype=hybrid&key=AIzaSyB1WHQPb434r37qVAQYoC53f5_cyaxMWmg`;
}

export const Canvas = ({ latLng = null }) => {
  const { editor, onReady, selectedObjects, deleteSelected } = useFabricJSEditor();
  const [isDrawing, setDrawing] = useState(true);
  const ref = useRef(null);
  const { image, takeScreenshot, isLoading, clear } = useScreenshot({ ref });

  const onAddCircle = () => {
    editor?.addCircle({
      left: 100,
      top: 100,
      radius: 100,
    });
  };
  const onAddRectangle = () => {
    editor?.addRectangle();
  };

  React.useEffect(() => {
    if (editor?.canvas) {
      editor.canvas.isDrawingMode = isDrawing;
      editor.canvas.freeDrawingBrush.color = "#ff2";
      editor.canvas.freeDrawingBrush.width = 5;
    }
    return () => {};
  }, [isDrawing, editor]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center ">
      <div className="">
        {/* <Button onClick={onAddCircle}>Add circle</Button>
        <Button onClick={onAddRectangle}>Add Rectangle</Button> */}
        <Button className={`${isDrawing ? "bg-lime-400" : ""}`} onClick={() => setDrawing((v) => !v)}>
          ✏️ Draw
        </Button>
        <Button
          onClick={() => {
            var lastItemIndex = editor?.canvas.getObjects().length - 1;
            var item = editor?.canvas.item(lastItemIndex);

            if (item.get("type") === "path") {
              editor?.canvas.remove(item);
              editor?.canvas.renderAll();
            }
          }}
        >
          {" "}
          ↩️ Undo
        </Button>
        <Button onClick={editor?.deleteSelected}> ✖ Selected</Button>
        <Button onClick={editor?.deleteAll}>❌ All</Button>
        <Button
          onClick={async () => {
            download(await takeScreenshot("png"), "image.png", "image/png");
          }}
        >
          💾 Save
        </Button>
      </div>

      <div
        ref={ref}
        className="bg-purple-500 bg-center bg-cover bg-no-repeat w-[600px] h-[600px]   max-w-full max-h-full"
        style={{ backgroundImage: `url("${getMapURL(latLng) || url}")` }}
      >
        <FabricJSCanvas className="sample-canvas w-[600px] h-[600px]  max-w-full max-h-full " onReady={onReady} />
      </div>
    </div>
  );
};

const Button = ({ className, ...props }) => (
  <button {...props} className={`px-2 py-1 rounded border  bg-slate-50 m-1 ${className} `}>
    {props.children}
  </button>
);
//http://fabricjs.com/freedrawing
