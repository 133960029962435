import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useState } from "react";

export const AddressInput = ({ value = { address: "" }, setValue, options, onBlur, ...props }) => {
  // const [value, setValue] = useState({ address: "" });
  const [error, setError] = useState(null);

  function handleSelect(address) {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setValue({ address, latLng }))
      .catch((error) => setError(error));
  }

  function handleBlur(e) {
    // onBlur(e);
    if (!value.latLng) {
      setValue({ address: "", latLng: null });
    }
  }

  return (
    <PlacesAutocomplete
      value={value["address"]}
      onChange={(value) => setValue({ address: value })}
      onSelect={(value) => handleSelect(value)}
      // searchOptions={options}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="flex items-center justify-center  w-full  ">
          <div className="max-w-lg w-full flex relative ">
            {/* <div>{JSON.stringify(value, null, 2) || ""}</div> */}
            <input
              className="px-4  py-1 my-1 rounded flex-grow outline-none text-sm "
              {...getInputProps({ onBlur: handleBlur, ...props })}
            />
            {suggestions?.length > 0 && (
              <div className="bg-white mt-0 z-10 absolute left-0 top-10 w-full text-xs">
                <ul className="w-full rounded">
                  {loading ? (
                    <li> Loading</li>
                  ) : (
                    suggestions.map((suggestion) => (
                      <li className="w-full flex px-2 py-0.5" key={suggestion.placeId} {...getSuggestionItemProps(suggestion)}>
                        <h2>{suggestion.formattedSuggestion.mainText}</h2>
                        <p>{suggestion.formattedSuggestion.secondaryText}</p>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
