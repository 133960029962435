import { Routes, Route, Link, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import download from "downloadjs";
import { Draw } from "./Draw";
import { Canvas } from "./Canvas";
import { useState } from "react";
import { AddressInput } from "./AddressInput";
import { MapDisplay } from "./MapDisplay";

export function App() {
  return (
    <div className="w-full h-full ">
      <Routes>
        <Route path="/canvas" element={<Canvas />} />
        <Route path="/map" element={<MapDisplay />} />
        <Route exact path="job/:id" element={<Job />} />
      </Routes>
    </div>
  );
}

export function Job(props) {
  let { id } = useParams();
  return <div>{id} data</div>;
}
